.wrapper{
    position: absolute;
    padding: 10px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: initAnimationWrapper 2s ease;
}

.timerElementsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.textLabel{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 20px;
    color: white;
    font-size: 12px;
    animation: initAnimationLabel .8s ease;
}

@media (orientation: portrait) {
    .timerElementsContainer.withMonths{
        width: 80%;
        margin: auto;
    }
    .timerElementsContainer.withYears{
        width: 60%;
        margin: auto;
    }
}

@media (orientation: landscape) {
    .timerElementsContainer{
        display: flex;
        flex-wrap: nowrap;
    }
}

@keyframes initAnimationWrapper{
    0% {
        transform: translateY(500px);
        opacity: 0;
    }
    25% {
        transform: translateY(0);
        opacity: .5;
    }
    100%{
        opacity: 1;
    }
}

@keyframes initAnimationLabel{
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }
    50% {
        transform: translateY(-100px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}